/* Container principal */
.price-box {
    background-color: #111;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    max-width: 900px;
    margin: 50px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    color: white;
    border: 3px solid rgba(255, 255, 255, 0.15); /* Borda com tom de branco puxado para o cinza */
  }
  
  /* Alinha conteúdo interno */
  .price-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  /* Conteúdo da parte esquerda (preço) */
  .left-content {
    flex: 1;
    padding-right: 30px;
    text-align: left;
  }
  
  .title {
    font-size: 1.8rem;
    font-weight: 700;
    color: #ffffff;
  }
  
  .installments {
    color: #9ca3af;
    font-size: 1rem;
  }
  
  .price-value {
    font-size: 4rem;
    font-weight: 800;
    color: #ffffff;
    margin: 10px 0;
  }
  
  .currency {
    font-size: 1.5rem;
  }
  
  .single-payment {
    color: #9ca3af;
    font-size: 1rem;
  }
  
  .cta-button {
    background-color: #00d1ff;
    padding: 15px 20px;
    border-radius: 40px;
    display: inline-block;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    margin: 15px 0;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .cta-button:hover {
    background-color: #0ce0ff;
  }
  
  .limited-offer {
    color: #9ca3af;
    font-size: 0.9rem;
  }
  
  /* Conteúdo da parte direita (benefícios) */
  .right-content {
    flex: 1;
    display: flex;
    justify-content: center; /* Centraliza o conteúdo */
    align-items: center; /* Alinha verticalmente no centro */
  }
  
  .benefits-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left; /* Alinha o texto à esquerda */
    color: #a7f3d0;
    font-size: 1rem;
  }
  
  .benefits-list li {
    margin-bottom: 10px;
  }
  
  .benefits-list li::before {
    content: "✔";
    color: #00ff88;
    margin-right: 10px;
  }
  
  /* Estilos para dispositivos móveis */
  @media (max-width: 768px) {
    .price-box {
      flex-direction: column;
      padding: 20px;
      margin: 20px; /* Reduz a margem externa para ficar mais próximo da borda */
    }
  
    .price-content {
      flex-direction: column;
    }
  
    .left-content {
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .right-content {
      padding-left: 0;
      justify-content: flex-start;
    }
  
    .cta-button {
      width: 100%;
      text-align: center;
    }
  }
  
  /* Estilos para dispositivos menores (smartphones) */
  @media (max-width: 480px) {
    .price-box {
      margin: 10px; /* Deixa uma margem bem curta nas laterais */
    }
  }
  