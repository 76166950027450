.footer{
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
} 

.poweredby-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

#poweredby-logo{
  margin-top: 10px;
  margin-bottom: 30px;

  max-width:200px !important;
  max-height:200px !important;
  width: auto !important;
  height: auto !important;
}

@media screen and (max-width: 760px){
  #poweredby-logo{
    max-width:150px !important;
    max-height:150px !important;
    width: auto !important;
    height: auto !important;
  }

  .poweredby-logo{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}