.Container-method {
  width: 100%;
  max-width: 1360px;
  margin: 0px auto;
  padding: 50px 80px;
}

@media screen and (max-width: 600px) {
  .Container-method {
    padding: 20px 20px;
  }
}

.Container-box-method {
  background: linear-gradient(#00293ad2 0%, black 100%);
}

.title-method {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px; /* Adiciona espaçamento para evitar que o título fique muito próximo das bordas */
}

.Title-style-method {
  font-size: 4rem;
  line-height: 4.56rem;
  font-weight: bolder !important;
  color: #ffffff;
  text-align: center; /* Mantém o título centralizado */
  word-wrap: break-word; /* Quebra a palavra se for necessário para evitar ultrapassar a tela */
}

@media screen and (max-width: 600px) {
  .Title-style-method {
    font-size: 2.5rem !important; /* Reduz o tamanho da fonte no mobile */
    line-height: 3.2rem !important; /* Aumenta o espaçamento entre as linhas no mobile */
    padding: 0 10px; /* Adiciona padding para garantir que o título não ultrapasse as bordas no mobile */
    word-wrap: break-word; /* Garante a quebra de palavras longas no mobile */
  }
}

.modules {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: stretch; /* Garante que os itens tenham a mesma altura */
  flex-wrap: wrap; /* Para garantir que eles se ajustem à largura da tela no mobile */
}

.first, .second {
  padding: 0px 40px 24px 16px;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

@media screen and (max-width: 1175px) {
  .first, .second {
    border-right: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding: 32px 0;
  }

  .modules {
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    overflow-x: hidden;
    max-width: 1000px;
  }
}

.subtitle-cards {
  text-align: start;
}

.box-title-cards {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title-cards {
  font-weight: bold;
  font-size: 2rem;
}

.icons-method {
  height: 75px;
}

@media screen and (max-width: 600px) {
  .modules {
    flex-direction: column; /* Ajusta as colunas para se comportarem como linhas no mobile */
    padding: 0px 15px; /* Adiciona espaço entre os módulos e a borda no mobile */
    gap: 30px; /* Aumenta o espaçamento entre os módulos no mobile */
  }

  .first, .second {
    padding: 32px 20px; /* Ajuste do padding no mobile */
    border: none; /* Remove as bordas no mobile */
  }

  .box-title-cards {
    padding: 0 10px; /* Adiciona espaço lateral no conteúdo */
    text-align: center; /* Centraliza o conteúdo dentro de cada módulo */
  }

  .title-method {
    padding: 0 10px; /* Garante que o título tenha margem no mobile */
  }

  .title-cards {
    font-size: 1.6rem; /* Reduz o tamanho da fonte no mobile */
  }
}
