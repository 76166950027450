.container-box-content {
  background: linear-gradient(#000000c2 0%, black 100%);
}

.container-content {
  width: 100%;
  max-width: 1360px;
  margin: 0px auto;
  padding: 50px 80px;
}

@media screen and (max-width: 600px) {
  .container-content {
    padding: 50px 20px;
  }
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

@media screen and (max-width: 600px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center; /* Centraliza o conteúdo no mobile */
    padding: 0 15px; /* Reduz o espaçamento nas laterais para evitar que a imagem ultrapasse */
  }
}

.text-content {
  flex: 1;
}

.subtitle-container-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title-content {
  margin-bottom: 15px;
  text-align: left;
}

.Title-style-content {
  font-size: 4rem;
  line-height: 4.56rem;
  font-weight: bolder !important;
  background: linear-gradient(91.06deg, #ffffff 25.26%, #008cc3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .Title-style-content {
    font-size: 3.2rem !important;
    line-height: 3.2rem !important;
    text-align: left;
  }
}

.subtitle-box-content {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
}

.subtitle-content {
  display: flex;
  align-items: flex-start;
  text-align: start;
  margin-top: 15px;
  margin-bottom: 15px;
}

.subtitle-style-content {
  color: #a19898;
  background-clip: text;
  margin: 0px;
  font-size: 1.125rem;
  font-weight: normal;
  max-width: 600px;
}

@media screen and (max-width: 480px) {
  .subtitle-style-content {
    font-size: 0.9rem;
  }
}

#hifen-topic {
  font-weight: bold;
  color: #008cc3;
}

.image-container {
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-image {
  width: auto;
  max-height: 500px;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .book-image {
    max-height: 450px;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  .book-image {
    max-height: 400px;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .book-image {
    max-height: 350px;
    height: auto;
    margin-top: 20px;
  }

  .image-container {
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    padding: 0 15px; /* Garante que a imagem não ultrapasse a tela no mobile */
  }
}

@media screen and (max-width: 500px) {
  .book-image {
    max-height: 320px;
    height: auto;
  }
}

@media screen and (max-width: 400px) {
  .book-image {
    max-height: 290px;
    height: auto;
  }
}

@media screen and (max-width: 350px) {
  .book-image {
    max-height: 280px;
    height: auto;
  }
}

@media screen and (max-width: 300px) {
  .book-image {
    max-height: 260px;
    height: auto;
  }
}
