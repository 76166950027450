/* Container principal */
.Who-is {
  background-color: #000000;
  color: white;
  width: 100%;
  padding: 50px 0;
}

/* Para desktops */
.container-whois {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 80px;
}

/* Imagem do autor no desktop */
.image-container {
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  max-height: 500px !important;
  width: 100%;
  height: auto; /* Garante que a altura seja proporcional */
  object-fit: contain; /* Exibe a imagem inteira sem cortar */
}

/* Texto alinhado à esquerda */
.container {
  flex: 1;
  padding-left: 40px;
  text-align: left;
}

/* Título */
.title-whois {
  margin-bottom: 10px;
}

.Title-style-whois {
  font-size: 4rem;
  line-height: 4.56rem;
  font-weight: bolder !important;
  background: linear-gradient(91.06deg, #ffffff 25.26%, #008cc3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Mensagem e descrição */
.welcome-message {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.author-description {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

.who-is-description {
  padding-right: 20px;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .container-whois {
    flex-direction: column;
    padding: 20px;
  }

  .image-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .photo {
    max-height: 350px !important;
    width: 100%;
    height: auto; /* Mantém a proporção da imagem */
    object-fit: contain; /* Exibe a imagem inteira sem cortar */
  }

  .container {
    padding-left: 0;
  }

  .Title-style-whois {
    text-align: center;
  }

  .who-is-description {
    text-align: left;
  }
}

/* Ajuste extra para telas menores */
@media screen and (max-width: 480px) {
  .photo {
    max-height: 300px !important;
    width: 100%;
    height: auto; /* Mantém a proporção da imagem */
    object-fit: contain; /* Exibe a imagem inteira sem cortar */
  }

  .Title-style-whois {
    font-size: 1.6rem !important;
    line-height: 2.7rem !important;
  }
}
