@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
    font-family: Poppins, "Segoe UI", Verdana, Arial, sans-serif;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    outline: 0px;
}

.App {
  text-align: center;
  box-sizing: border-box;
}

.App-header {
  background-color: #000000;
  color: white;
}

.container-linear{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.linear{
    width: 2px;
    height: 80px;
    background: linear-gradient(180deg, #66f5ff00,#008cc3);
}

/* .container-arrows{

  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  left: 50%;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 30px;
  }
} */
.container-arrows {
    -webkit-animation: action 0.5s infinite  alternate;
    animation: action 0.5s infinite  alternate;
}

@-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}


.arrows{
  height: 65px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#divider-box{
  border: 1px solid #464a4b9f;

  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}

#footer-divider{
  border: 1px solid #a4a4a49f;

  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo {
  margin-top: 10px;
  margin-bottom: 30px;

  /* Definição de tamanho responsivo para o logo */
  max-width: 75px;
  max-height: 75px;
  width: 100%; /* Certifica-se de que o logo é dimensionado proporcionalmente */
  height: auto;
}

/* Ajustes para Mobile */
@media screen and (max-width: 760px) {
  #logo {
    max-width: 35px; /* Reduz o tamanho do logo no mobile */
    max-height: 35px;
    width: 100%; /* Mantém o aspecto proporcional */
    height: auto;
  }

  .logo {
    justify-content: center; /* Certifica-se de que o logo é centralizado no mobile */
    align-items: center;
  }
}

