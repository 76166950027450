html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Garante que o body ocupe toda a altura */
}

.first-part {
  background: url(../images/background-header.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Faz o background cobrir toda a área */
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Media query para telas menores (mobile) */
@media screen and (max-width: 768px) {
  .first-part {
    background: url(../images/background-header-mobile.svg); /* Imagem diferente para o mobile */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Cobrir toda a área no mobile */
  }
}



.Title{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 480px){
  .Title{
    padding: 0px 15px 15px 15px;
  }
}

.Title-style{
  font-size: 4rem;
  line-height: 4.56rem;

  font-weight: bolder !important;
  margin: 0px;
  justify-content: center;
  max-width: 850px;

  color: #ffffff;
}

#title-change{
   background: linear-gradient(91.06deg,#ffffff 2.26%, #008cc3 100%);
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (max-width: 600px){
  #title{
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media screen and (max-width: 480px){
  #title{
    font-size: 2.3rem;
    line-height: 2.7rem;
  }
}

.subtitle{
  color: #ffffff;
  background-clip: text;
  margin: 0px;

  font-size: 1.125rem;
  font-weight: normal;
  max-width: 600px;
  margin-top: 8px;
  opacity: 0.6; 
}

@media screen and (max-width: 480px){
  #subtitle{
    font-size: 0.9rem;
  }
}

.Btn-invite{
  background: #008cc3;
  transition: all 0.3s;
  color: white;
  font-size: 1.125rem;
  font-weight: bold;

  width: auto;
  height: auto;
  text-decoration: none;
  
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 80px;
  margin-bottom: 40px;
  padding: 10px 30px;
  border-radius: 10px;
  line-height: 2.1rem;
  max-width: 34.2rem;
}

/* .Btn-invite:hover{
  border: 3px solid transparent;
  background: linear-gradient( 95.27deg, #0894f1 -0.85%, #03b7e4 39.07%, #00F0FF 89.32% ) padding-box,
  linear-gradient(91.06deg, #FF1CF7 2.26%, #00F0FF 100%) border-box; 
} */

.btn-icon{
  height: auto;
  margin-right: 16px;
  margin-top: 8px;
}

@media screen and (max-width: 480px){
  #btn-invite{
    font-size: 0.9rem;
  }
}

#divider{
  border: 1.5px solid #464a4b9f;

  margin-bottom: 0px;
  margin-top: 0px;
  width: 50%;
}