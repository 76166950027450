.container-box-how {
  background: linear-gradient(black 0%, #00293ad2 100%);
}

.container-how {
  width: 100%;
  max-width: 1360px;
  margin: 0px auto;
  padding: 40px 80px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .container-how {
    padding: 20px 20px;
  }
}

.title-how {
  margin-bottom: 35px;
  text-align: left; /* Alinhando o título à esquerda */
}

.Title-style-how {
  font-size: 4rem;
  line-height: 4.56rem;
  font-weight: bolder !important;
  background: linear-gradient(91.06deg, #fdfdfd 25.26%, #008cc3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (max-width: 600px) {
  .Title-style-how {
    font-size: 3.2rem !important;
    line-height: 3.8rem !important;
  }
}

.how-is-description {
  font-weight: normal !important;
  max-width: 895px;
  text-align: left; /* Alinhando o subtítulo à esquerda */
  font-size: 1.125rem;
}

@media screen and (max-width: 1225px) {
  .how-is-description {
    max-width: 765px;
  }
}
@media screen and (max-width: 1125px) {
  .how-is-description {
    max-width: 665px;
  }
}
@media screen and (max-width: 480px) {
  #how-is-description {
    font-size: 0.9rem;
  }
}

#icon-how {
  max-width: 500px !important;
  max-height: 500px !important;
  width: auto !important;
  height: auto !important;
  opacity: 0.6;
}

@media screen and (max-width: 800px) {
  #icon-how {
    max-width: 500px !important;
    max-height: 500px !important;
    width: auto !important;
    height: auto !important;
  }
}
@media screen and (max-width: 600px) {
  #icon-how {
    max-width: 400px !important;
    max-height: 400px !important;
    width: auto !important;
    height: auto !important;
  }
}
@media screen and (max-width: 500px) {
  #icon-how {
    max-width: 300px !important;
    max-height: 300px !important;
    width: auto !important;
    height: auto !important;
  }
}
@media screen and (max-width: 400px) {
  #icon-how {
    max-width: 200px !important;
    max-height: 200px !important;
    width: auto !important;
    height: auto !important;
  }
}

.aws-how {
  width: 100%;
  max-width: 250px;
}

#aws-icon-how {
  max-width: 250px !important;
  max-height: 250px !important;
  width: auto !important;
  height: auto !important;
  margin-top: 20px;
}

@media screen and (max-width: 1040px) {
  #aws-icon-how {
    max-width: 200px !important;
    max-height: 200px !important;
    margin-top: 55px;
  }
}
@media screen and (max-width: 989px) {
  #aws-icon-how {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .how-body {
    align-items: center !important;
    justify-content: center !important;
  }
}

#how-is-right {
  font-size: 4rem;
  line-height: 4.56rem;
  color: #00F0FF;
}

@media screen and (max-width: 480px) {
  #how-is-right {
    font-size: 2rem !important;
    line-height: 2.7rem !important;
  }
}

@media screen and (max-width: 600px) {
  #how-is-right {
    font-size: 3.2rem !important;
    line-height: 3.8rem !important;
  }
}

.how-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

/* Container para centralizar as imagens do ebook */
.ebook-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

/* Imagem principal do ebook, ajustável para todos os tamanhos de tela */
.ebook-image {
  max-width: 80%; /* Limita a largura máxima da imagem a 80% da largura da tela */
  height: auto; /* Mantém a proporção da imagem */
  display: block;
  margin: 0 auto;
}

/* Estilo para imagem em dispositivos móveis (telas até 767px de largura) */
@media (max-width: 767px) {
  .ebook-image {
    max-width: 90%; /* Margem maior nas laterais para telas pequenas */
    margin-left: 5%; /* Espaçamento da borda esquerda */
    margin-right: 5%; /* Espaçamento da borda direita */
  }
}

/* Estilo para tablets (telas entre 768px e 1024px de largura) */
@media (min-width: 768px) and (max-width: 1024px) {
  .ebook-image {
    max-width: 70%; /* Um tamanho menor que o desktop, mas ainda em destaque */
  }
}

/* Estilo para telas grandes (acima de 1024px) */
@media (min-width: 1025px) {
  .ebook-image {
    max-width: 50%; /* Limita a imagem a 50% da largura da tela */
  }
}
